import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CloseIcon } from '../../homeS3Icon';
import ModalBottom from '../../modal/ModalBottom';

const CalendarStyle = styled.div`
	.react-calendar {
		background: ${({ theme }) => (theme === 'dark' ? '#34353f' : '#f5fbff')} !important;
		border: none !important;
		border-radius: 13.1px !important;
		overflow: hidden !important;
	}

	.react-calendar__month-view__days {
		border-bottom-left-radius: 13.1px !important;
	}
	.react-calendar__tile {
		border-radius: 10px;
		height: 44px;
		padding: 0 !important;
		margin-top: 3px !important;
		margin-bottom: 3px !important;
		font-size: 16px !important;
		font-weight: 600 !important;
		background-color: transparent !important;
		transition: 0.2s ease-in-out;
	}

	.react-calendar__tile > abbr {
		background-color: ${({ theme }) => (theme === 'dark' ? '#235dff4d' : '#b6ccff')} !important;
		width: 39px !important;
		height: 39px !important;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px !important;
		color: ${({ theme }) => (theme === 'dark' ? 'white' : 'inherit')};
	}

	.react-calendar__tile.react-calendar__tile--active > abbr {
		background-color: #235dff !important;
	}

	.react-calendar__tile:disabled > abbr {
		background-color: ${({ theme }) => (theme === 'dark' ? '#34353f' : '#f5fbff')} !important;
	}

	.react-calendar__navigation__label,
	.react-calendar__navigation__next-button,
	.react-calendar__navigation__prev-button {
		background-color: ${({ theme }) => (theme === 'dark' ? '#34353f' : 'transparent')} !important;
		color: ${({ theme }) => (theme === 'dark' ? 'white' : 'inherit')};
	}

	.react-calendar__month-view__weekdays__weekday--weekend,
	.react-calendar__month-view__weekdays__weekday {
		color: ${({ theme }) => (theme === 'dark' ? 'white' : 'inherit')};
	}
`;

const ModalDepartureDate = ({
	modalDepartureDate,
	setModalDepartureDate,
	list,
	obj,
	setObj,
	objError,
	setObjError,
	departureDateList,
	date = new Date(),
	setDate,
	getNumberOfDays,
	departureDateObjParams,
	setDepartureDateObjParams,
	getDepartureDate,
	setModalNumberOfTourists,
	darkMode,
	setModalNumberOfDays,
}) => {
	const { t } = useTranslation();
	const onChange = newDate => {
		setDate(newDate);

		const dd = new Date(newDate);
		const year = dd.getFullYear();
		const month = String(dd.getMonth() + 1).padStart(2, '0');
		const day = String(dd.getDate()).padStart(2, '0');

		setObj(prev => ({
			...prev,
			departure_date: `${year}-${month}-${day}`,
		}));

		setObjError(prev => ({
			...prev,
			departure_date: false,
		}));

		const { somo_id, tour_operator_id, state_id, checkin, towns } = departureDateObjParams;
		const searchParams = new URLSearchParams(window.location.search);

		const paramsObject = {};

		// Iterate over the search parameters and add them to the paramsObject
		for (const [key, value] of searchParams.entries()) {
			if (key.endsWith('[]')) {
				const baseKey = key.slice(0, -2); // Remove the '[]' from the key
				if (!paramsObject[baseKey]) {
					paramsObject[baseKey] = [];
				}
				paramsObject[baseKey].push(isNaN(value) ? value : Number(value));
			} else if (key === 'checkin') {
				// Convert checkin date format from YYYY-MM-DD to YYYYMMDD
				paramsObject[key] = value.replace(/-/g, '');
			} else {
				paramsObject[key] = isNaN(value) ? value : Number(value);
			}
		}

		if (somo_id !== undefined && tour_operator_id !== undefined && state_id !== undefined) {
			getDepartureDate(somo_id, tour_operator_id, state_id, `${year}${month}${day}`, towns);
		}

		setModalDepartureDate(false);
		setModalNumberOfDays(true);
	};

	// Function to disable all dates except those in allowedDates array
	const tileDisabled = ({ date, view }) => {
		if (view === 'month') {
			return !departureDateList.some(allowedDate => {
				const [year, month, day] = allowedDate?.start.split('-').map(Number);
				return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
			});
		}
		return false;
	};

	return (
		<div className='w-full'>
			<ModalBottom
				close={() => setModalDepartureDate(false)}
				modal={modalDepartureDate}
				heightModal='min-h-[20vh] max-h-[80vh]'
				btnShow={false} // Hide the button
				translateY='translate-y-[80vh]'
				clickFunction={null} // Remove the clickFunction since we don't need the button
				content={
					<div className='w-full'>
						<div className='flex items-center justify-between w-full gap-3'>
							<p className='font-semibold text-xl dark:text-white'>{t('home.fly_day')} ?</p>
							<CloseIcon fill={darkMode ? '#fff' : '#2B3F5A'} className='cursor-pointer' onClick={() => setModalDepartureDate(false)} />
						</div>
						<div className='flex w-full justify-center mt-6 mb-6'>
							<CalendarStyle theme={darkMode ? 'dark' : 'light'}>
								<Calendar onChange={onChange} value={date} tileDisabled={tileDisabled} />
							</CalendarStyle>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ModalDepartureDate;
