import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { BookingIcon, FeaturedIcon, MoreIcon, PalmaTree } from '../components/headIcons';
import ModalLogin from './pages/auth/ModalLogin';

const MobileTabStyle = styled.div`
	/* height: 60px;s; */
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1030;
	width: 100%;
	justify-content: center;
	display: flex;
	& .link {
		cursor: pointer;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		& .icon {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& .title {
			margin-top: 5px;
			color: #9e9e9e;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			text-align: center;
		}
		&:hover,
		&.active {
			& .icon {
				#mask0_1854_535,
				#mask0_1858_958 {
					& g > path,
					& path {
						fill: #fff !important;
					}
				}
				& g > path,
				& path {
					fill: #235dff !important;
					stroke: #235dff !important;
				}
			}
			& .my-apps {
				& circle,
				& path {
					stroke: #235dff;
				}
			}
			& .title {
				color: #235dff;
			}
		}
	}
`;
const MobileTab = ({ darkMode }) => {
	const { user, login_modal } = useSelector(s => s);
	const { t, i18n } = useTranslation();

	return (
		<>
			<MobileTabStyle className={`!bg-white dark:!bg-[#242429] `}>
				<div
					className={` mobile-tab  container_main w-full flex items-center  py-[17px] !px-0 bg-white border-t border-[#dee7ff]   dark:!bg-[#242429] dark:border-[#646778] dark:border-t 
					`}
				>
					<div className={`flex justify-around items-center w-full   `}>
						{/* <NavLink className='link' to='/'>
							<div className='icon h-[25px]'>{homeIcon}</div>
							<div className='title'>{t(home.home)}</div>
						</NavLink> */}
						{/* findtour */}
						<NavLink className='link' to='/'>
							<div className='my-apps '>{PalmaTree}</div>
							<div className='title'>{t('home.find_tour')}</div>
						</NavLink>
						<NavLink className='link min-h-[50px]' to='/mybooking'>
							<div className='icon  min-h-[30px]'>{BookingIcon}</div>
							<div className='title'>{t('home.my_reservations')}</div>
						</NavLink>
						<NavLink className='link ' to='/basket'>
							<div className='icon '>{FeaturedIcon}</div>
							<div className='title'>{t('home.featured')}</div>
						</NavLink>
						{/* {user?.user?.id ? (
							<NavLink className='link' to='/services'>
								<div className='my-apps '>{MoreIcon}</div>
								<div className='title'>Еще</div>
							</NavLink>
						) : (
							<div className='link  cursor-not-allowed'>
								<div className='my-apps '>{MoreIcon}</div>
								<div className='title'>Еще</div>
							</div>
						)} */}
						<NavLink className='link' to='/services'>
							<div className='my-apps '>{MoreIcon}</div>
							<div className='title'>{t('home.more')}</div>
						</NavLink>
						{/* {user?.user?.id ? (
              <NavLink className="link" to="/services">
                <div className="icon h-[25px]">{ServiceIcon}</div>
                <div className="title">Сервисы</div>
              </NavLink>
            ) : (
              <div
                className="link"
                onClick={() => dispatch({ type: "LOGIN_MODAL", payload: true })}
              >
                <div className="icon">{accountIcon}</div>
                <div className="title">Войти</div>
              </div>
            )} */}
						{/* <NavLink className="link" to="/wallet">
            <div className="icon">{WalletIcon}</div>
            <div className="title">Журнал</div>
          </NavLink> */}
						{/* {user?.id ? (
            <NavLink className="link" to="/basket">
              <div className="my-apps">{SaveIcon}</div>
              <div className="title">Избранные</div>
            </NavLink>
          ) : (
            <div
              className="link"
              onClick={() => {
                dispatch({ type: "SET_AUTH_MODAL", payload: true });
              }}
            >
              <div className="my-apps">{MyApps}</div>
              <div className="title">Мои заявки</div>
            </div>
          )} */}
						{/* <NavLink className="link" to="/aksiya">
            <div className="icon">{FireIcon}</div>
            <div className="title">Акции</div>
          </NavLink> */}

						{/* {user?.id ? (
            <NavLink className="link" to="/profile">
              <div className="icon">{accountIcon}</div>
              <div className="title">Аккаунт</div>
            </NavLink>
          ) : (
            <div
              className="link"
              onClick={() => {
                dispatch({ type: "SET_AUTH_MODAL", payload: true });
              }}
            >
              <div className="icon">{accountIcon}</div>
              <div className="title">Войти</div>
            </div>
          )} */}
					</div>
				</div>
			</MobileTabStyle>
			<ModalLogin
				modal={login_modal}
				darkMode={darkMode}
				// setObj={setObj}
				// obj={obj}
				// objError={objError}
				// setObjError={setObjError}
			/>
		</>
	);
};

export default MobileTab;
