import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Title from '../components/Title';
import TourIncludes from '../components/TourIncludes';
import MobileTab from '../components/mobiletab';
import ClickTravel from '../components/pages/home/ClickTravel';
import Axios from '../utils/httpsClinet';

const AddFav = styled.div`
	position: relative;
	& .add-favorites {
		border-radius: 50%;
		height: 36px;
		right: 18px;
		top: 16px;
		width: 36px;
		& svg {
			transition: 0.3s;
		}
		& .fixed-svg {
			left: -0.5px;
			position: absolute;
			top: 8.8px;
		}
		& .like {
			transform: scale(0);
		}
		&:active {
			& svg {
				transform: scale(1.3);
			}
		}
	}
	& .liked {
		background: transparent;
	}
`;

const Basket = ({ darkMode }) => {
	const [data, setData] = useState([]);
	const [favourites, setFavourites] = useState([]);
	const [loading_f, setLoadingF] = useState(false);
	const [favouriteId, setFavouriteId] = useState(0);
	const [strParams, setStrParams] = useState('');
	const navigate = useNavigate();
	const { t } = useTranslation();
	useEffect(() => {
		getFavourites();
	}, []);
	const changeFavourite = id => {
		setLoadingF(true);
		setFavouriteId(id);
		Axios()
			.post(`api/v1/like-prices`, { price_id: id })
			.then(res => {
				const isFavorited = favourites.some(fav => fav.price_id === id);
				if (isFavorited) {
					setFavourites(favourites.filter(favid => favid?.price_id !== id));
				} else {
					setFavourites([...favourites, { price_id: id }]);
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoadingF(false);
			});
	};

	const getFavourites = () => {
		Axios()
			.get(`api/v1/get-like-prices`)
			.then(res => {
				let data = res?.data?.data?.data;
				let newD = [];
				data.forEach(el => {
					newD = [
						...newD,
						{
							price_id: el?.price?.id,
						},
					];
				});
				setFavourites(newD);

				setData(res?.data?.data?.data);
			});
	};
	const colors = ['#FF5733', '#33FF57', '#3B71FE', '#0096FF', '#FFC273'];

	const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

	return (
		<div className='bg-[#F5F5F5]  dark:bg-[#242429]  pb-28'>
			<div className=' container_main !px-0'>
				<div className='bg-white dark:bg-[#34353F]  text-[#2B3F5A] dark:text-white text-lg font-semibold py-[20px] text-center'>{t('home.choose')}</div>
				<div className=' min-h-[85vh] mt-[25px] dark:text-white'>
					{data.map((item, index) => {
						let clickTag = [];
						item?.tags?.forEach(el => {
							clickTag = [
								...clickTag,
								{
									title: el?.tag,
									color: el?.color,
								},
							];
						});
						const isAddedToFavourite = favourites?.some(d => d.price_id === item?.price?.id);

						return (
							<React.Fragment key={index}>
								<div className='min-h-[271px] mt-[15px] overflow-hidden rounded-[18.07px] bg-white dark:bg-[#34353F]'>
									<div className='relative h-[193px] overflow-hidden'>
										{item?.photos?.length > 0 ? (
											<Swiper
												pagination={{
													dynamicBullets: true,
												}}
												modules={[Pagination]}
												className='mySwiper'
											>
												{item?.photos?.map((item_in, index_in) => (
													<SwiperSlide>
														<img
															key={index_in}
															src={item_in?.full_path}
															className='w-full h-full object-cover rounded-tl-[18.07px] rounded-tr-[18.07px]'
															alt=''
														/>
													</SwiperSlide>
												))}
											</Swiper>
										) : (
											<img
												onError={e => {
													e.target.src = 'https://cdn0.hitched.co.uk/vendor/4332/3_2/1280/png/frame-1_4_24332-169089063311545.webp';
												}}
												src={item?.image_url}
												className='w-full h-full object-cover rounded-tl-[18.07px] rounded-tr-[18.07px]'
												alt=''
											/>
										)}

										<div className='absolute top-[16.5px] right-[16.5px] z-30'>
											<AddFav className='fav bg-[#FFFFFFBF] rounded-[10px] p-[10px] w-[35px] h-[35px] flex justify-center items-center'>
												<button onClick={() => changeFavourite(item?.price?.id)} className={!isAddedToFavourite ? 'fixed-svg' : 'like fixed-svg'}>
													<>
														{loading_f && favouriteId === item?.price?.id ? (
															<ClipLoader size={24} />
														) : (
															<>
																{isAddedToFavourite ? (
																	<svg xmlns='http://www.w3.org/2000/svg' width='28' height='26' viewBox='0 0 28 26' fill='none'>
																		<path
																			d='M24.069 3.05825L24.0683 3.05765C21.214 0.501861 17.0517 1.03082 14.4771 3.78138L14.4771 3.78133L14.4707 3.78836L14.0122 4.29406L13.5228 3.7813C10.9911 1.02672 6.78223 0.505279 3.93168 3.05765L3.93101 3.05825C0.727822 5.93508 0.579672 11.0279 3.42259 14.1131L3.42353 14.1141L12.5035 23.9224C13.3024 24.8222 14.6976 24.8222 15.4965 23.9224L24.5765 14.1141L24.5774 14.1131C27.4203 11.0279 27.2722 5.93508 24.069 3.05825Z'
																			fill='#235DFF'
																			stroke='#235DFF'
																			stroke-width='1.19444'
																		/>
																	</svg>
																) : (
																	<svg xmlns='http://www.w3.org/2000/svg' width='28' height='26' viewBox='0 0 28 26' fill='none'>
																		<path
																			d='M24.069 3.05825L24.0683 3.05765C21.214 0.501861 17.0517 1.03082 14.4771 3.78138L14.4771 3.78133L14.4707 3.78836L14.0122 4.29406L13.5228 3.7813C10.9911 1.02672 6.78223 0.505279 3.93168 3.05765L3.93101 3.05825C0.727822 5.93508 0.579672 11.0279 3.42259 14.1131L3.42353 14.1141L12.5035 23.9224C13.3024 24.8222 14.6976 24.8222 15.4965 23.9224L24.5765 14.1141L24.5774 14.1131C27.4203 11.0279 27.2722 5.93508 24.069 3.05825Z'
																			fill='white'
																			stroke='#235DFF'
																			stroke-width='1.19444'
																		/>
																	</svg>
																)}
															</>
														)}
													</>
												</button>
											</AddFav>
										</div>
									</div>
									<Title
										darkMode={darkMode}
										size='[20px]'
										className='px-2 cursor-pointer hover:underline'
										text={item?.name}
										onClick={() => {
											navigate(
												`/hotels/detail/?town_from_inc=${item?.town_from_inc}&tour_operator_id=${item?.price?.tour_operator_id}&state_id=${item?.price?.state_inc}&checkin=${item?.price?.check_in}&nights=${item?.price?.nights}&adult=${item?.price?.adult}&childs=${item?.price?.child}&towns[]=${item?.price?.tour_key}&town_names[]=${item?.price?.town_from_id}&hotel_key=${item?.price?.hotel_key}&id=${item?.price?.id}`
											);
										}}
									/>
									<div className='flex gap-[5px] '>
										{item?.rating?.[0]?.rating ? (
											<div className='pl-2  text-[#2B3F5A] dark:text-white text-[19px] font-medium'>{item?.rating?.[0]?.rating}</div>
										) : null}
										{darkMode ? <img className='pl-2' src='/images/tripadvisor-white.svg' alt='' /> : <img src='/images/tripadvisor.svg' alt='' />}{' '}
									</div>
									{clickTag?.length > 0 ? (
										<ClickTravel
											list={clickTag}
											title='Click Travel - отдых без хлoпот'
											darkMode={darkMode}
											shadow={false}
											titleShow={false}
											randomTrue={true}
											getRandomColor={getRandomColor}
										/>
									) : null}
									{item?.infos?.[0]?.description ? (
										<p className='text-[#2B3F5A] dark:text-white py-[10px] px-2'>
											{item?.infos?.[0]?.description}
											{/* Элитный отель с изысканными номерами, элегантным рестораном, открытым бассейном и баром. */}
										</p>
									) : null}
									<div className=''>
										<img src='/images/linedashed.svg' className='w-full object-cover h-[0.5px] mt-[15px]' alt='' />
										{/* <Link to="/hotels/detail/hoteltourbooking/"> */}
										<Link
											to={`/hotels/detail/?town_from_inc=${item?.town_from_inc}&tour_operator_id=${item?.price?.tour_operator_id}&state_id=${item?.price?.state_inc}&checkin=${item?.price?.check_in}&nights=${item?.price?.nights}&adult=${item?.price?.adult}&childs=${item?.price?.child}&towns[]=${item?.price?.tour_key}&town_names[]=${item?.price?.town_from_id}&hotel_key=${item?.price?.hotel_key}&id=${item?.price?.id}`}
										>
											<div className='p-[15px] flex gap-3 justify-between w-full relative'>
												<div className={`text-base font-normal ${darkMode ? '!text-white' : ''}`}>
													<span>
														{item?.price?.adult} {t('home.turist')},{' '}
													</span>
													<span>
														{item?.price?.nights} {t('home.nights')}
														{/* , {item?.price?.room_key} номер */}
													</span>
												</div>
												<div className='flex items-center gap-3'>
													<p className={`font-semibold text-xl ${darkMode ? '!text-white' : ''}`}>
														{item?.price?.converted_price_number?.toLocaleString('en-US').replace(/,/g, ' ')} {t('home.uzs')}
													</p>
												</div>
											</div>
										</Link>
									</div>
								</div>
								{index === 0 ? <TourIncludes /> : null}
							</React.Fragment>
						);
					})}
				</div>
				<MobileTab darkMode={darkMode} />
			</div>
		</div>
	);
};

export default Basket;
