import { useNavigate } from 'react-router-dom';
import { Back } from './itemIcon';

const NavigationOne = ({ darkMode, linkClose = false, text = '', bg = 'bg-white' }) => {
	const navigate = useNavigate();

	const handleBackClick = () => {
		if (linkClose) {
			window.location.replace('clickup://actions/close_screen');
		} else {
			navigate(-1);
		}
	};

	return (
		<div className='container_main !px-0'>
			<div className={` fixed  z-40 container_main  w-full  rounded-b-[20px]   ${bg} dark:bg-blackShark `}>
				<div className=' flex items-center justify-between  h-[65px] '>
					{linkClose ? (
						<a href='clickup://actions/close_screen'>
							<Back color={darkMode ? '#fff' : '#2B3F5A'} className='-translate-x-[15px]' />{' '}
						</a>
					) : (
						<Back color={darkMode ? '#fff' : '#2B3F5A'} onClick={handleBackClick} className='-translate-x-[15px]' />
					)}
					<div className='text-[#2B3F5A] text-lg font-semibold  dark:text-white'>{text}</div>
					<div className='translate-x-[15px] w-[30px]'> </div>
				</div>
			</div>
		</div>
	);
};

export default NavigationOne;
